@import './base/export';

* {
	@apply box-border;
	font-family: 'Roboto', sans-serif;
}

html {
	background: #030000;
}

body {
	@apply m-0 p-0 w-full h-full absolute;
	@apply h-screen w-screen overflow-x-hidden;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	#root {
		@apply h-screen w-screen overflow-x-hidden;
		background: #030000;
		@apply min-h-full overflow-auto;
	}

	&::-webkit-scrollbar {
		@apply w-1 bg-secondary;
	}
	&::-webkit-scrollbar-thumb {
		@apply bg-primary rounded-lg;
	}
}

code {
	@apply font-semibold;
	font-family: 'JetBrains Mono', monospace;
}

.fa-icon {
	@apply fill-current;
}

a:hover {
	@apply no-underline;
}
