@import './mixin';
@import './media';
@import './colors';
@import './fonts';

.rs-btn {
	.fa-icon {
		@apply mr-2.5 float-left;
	}
}

.rs-input:focus {
	@apply border-0 shadow-none;
}

.rs-loader-wrapper {
	@apply absolute inset-0 bg-secondary text-white z-40;
}
.rs-loader-spin {
	&::after {
		@apply border-0 border-t-4 border-solid border-primary;
	}
	&::before {
		@apply border-4 border-solid border-secondary border-opacity-10;
	}
}

.rs-breadcrumb {
	@apply m-0 p-0;
}

.rs-table-row-header {
	@apply bg-transparent;
}

.rs-grid-container,
.rs-row {
	@apply w-full m-0 p-0;
}

.rs-toast-container {
	@apply font-light tracking-wide text-sm;
	.rs-notification-description {
		color: inherit;
		@apply flex-center;
		.fa-icon {
			@apply w-7 mr-3;
		}
	}
	.rs-custom-toast {
		width: 250px;
		.rs-notification-content {
			@apply py-2;
		}
		.rs-notification-description {
			@apply text-white  justify-start;
		}
	}
}

.rs-pagination {
	@apply mt-5;
	.rs-pagination-btn-active {
		@apply bg-primary text-white border-primary font-bold;
	}
	.rs-pagination-btn {
		@apply flex-center  rounded-full
		 hover:bg-primary hover:text-white text-base px-2;
		.rs-pagination-symbol {
			@apply shadow-none;
			@apply hover:bg-transparent text-lg text-secondary;
		}
	}
}

.rs-panel {
	@apply relative;
}

.panel-body-none {
	.rs-panel-body {
		@apply hidden;
	}
}

.rs-modal-wrapper {
	@apply flex-center;
}

.rs-notification {
	.rs-btn-close {
		svg {
			color: white !important;
		}
	}
}
