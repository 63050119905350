@import './base/export';

.fa-btn {
	border: 1px solid;
	@apply rounded-full py-1.5 px-4 text-sm font-normal
	 border-secondary border-opacity-10;
	.fa-icon {
		@apply w-5;
	}
}

.fa-btn ~ .fa-btn {
	@apply ml-1.5;
}

.loading-cover {
	min-height: 300px;
}

.rs-panel-group .rs-panel + .rs-panel:before {
	@apply border-0;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
	box-shadow: none !important;
}
